// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';




// 懒加载路由组件，提升应用性能
const AdminLogin = () => import(/* webpackChunkName: "admin-login" */ '../views/AdminLogin.vue');
const MainPage = () => import(/* webpackChunkName: "main-page" */ '../views/MainPage.vue');
const ClientManagement = () => import(/* webpackChunkName: "client-management" */ '../views/ClientManagement.vue');
const ClientDetails = () => import(/* webpackChunkName: "client-details" */ '../views/ClientDetails.vue');
const MediaLibrary = () => import(/* webpackChunkName: "media-library" */ '../views/MediaLibrary.vue');
const SocialMediaAPIs = () => import(/* webpackChunkName: "social-media-apis" */ '../views/SocialMediaAPIs.vue');
const ChatIntegration = () => import(/* webpackChunkName: "chat-integration" */ '../views/ChatIntegration.vue');
const NotFound = () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'); // 404 页面
const Infoget = () => import(/* webpackChunkName: "infoget" */ '../views/kefu.vue'); // 新添加的 Infoget 组件
const ProductSkuComponent = () => import(/* webpackChunkName: "productSkuComponent" */ '../views/ProductSkuComponent.vue'); // 新添加的 ProductSkuComponent 组件
// 新增的卡密管理和添加卡密页面
const ManageCards = () => import(/* webpackChunkName: "manage-cards" */ '../views/ManageKeys.vue');
const AddCard = () => import(/* webpackChunkName: "add-card" */ '../views/AddKeys.vue');



const routes = [
  {
    path: '/',
    redirect: '/admin/login', // 根路径重定向到登录页
  },
  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: AdminLogin,
    meta: { guest: true }, // 仅供未登录用户访问
  },
  {
    path: '/main',
    component: MainPage,
    meta: { requiresAuth: true }, // 需要认证的路由
    children: [
      {
        path: '/',
        redirect: 'clients', // 当访问 /main 时，重定向到 /main/clients
      },
      {
        path: 'clients',
        name: 'ClientManagement',
        component: ClientManagement,
        // 子路由自动继承父路由的 meta
      },
      {
        path: 'clients/:id',
        name: 'ClientDetails',
        component: ClientDetails,
        props: true, // 将路由参数作为 props 传递给组件
        // 子路由自动继承父路由的 meta
      },
      {
        path: 'media-library',
        name: 'MediaLibrary',
        component: MediaLibrary,
        // 子路由自动继承父路由的 meta
      },
      {
        path: 'social-media-apis',
        name: 'SocialMediaAPIs',
        component: SocialMediaAPIs,
        // 子路由自动继承父路由的 meta
      },
      {
        path: 'chat-integration',
        name: 'ChatIntegration',
        component: ChatIntegration,
        // 子路由自动继承父路由的 meta
      },
      {
        path: 'inforgetComponent', // 新添加的路由
        name: 'InforgetComponent',
        component: Infoget,
        // 子路由自动继承父路由的 meta
      },
      {
        path: 'productSkuComponent', // 新添加的路由
        name: 'ProductSkuComponent',
        component: ProductSkuComponent,
        // 子路由自动继承父路由的 meta
      },
            // 新增的卡密管理页面路由
      {
        path: 'manage-cards',
        name: 'ManageCards',
        component: ManageCards,
      },
      // 新增的添加卡密页面路由
      {
        path: 'add-card',
        name: 'AddCard',
        component: AddCard,
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*', // 通配符路由，匹配所有未定义的路径
    name: 'NotFound',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


// 全局导航守卫
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('jwt_token');
  if (to.matched.some((record) => record.meta.requiresAuth) && !token) {
    next('/admin/login');
  } else {
    next();
  }
});


export default router;
